.downloader {
  width: 320px;
  min-height: 128px;
  position: fixed;
  right: 18px;
  bottom: 18px;
  max-height: 700px;
  overflow-y: auto;
}
.downloader .card-header {
  color: #fff;
  background-color: #F15D22;
}


.downloader .card .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
.textelip{
  text-overflow: ellipsis;
  max-width: 100px;
}

@media (max-width:390px){


  .downloader{
    width: 240px;
}
.card-header {
  font-size:small !important;
}

}
