.transparnt{
    opacity:1;
    position: fixed;
    top:50%;
    left: 50%;
    margin: 0 auto;
    z-index: 99999999999;
  box-sizing: border-box;
  transform: translate(-50%, -50%);

}
