.article-details {
  .article-headline {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0.0583333px;
    font-size: xx-large;
  }

.article-divelem{
  font-family: Work Sans;
  font-style: normal;
  font-weight: 100;
  line-height: 12px;
  font-size: small;
  text-transform: uppercase;
  /* Text - med grey */
  margin: 0;
  color: #9f9f9f;
}
  .article-publication {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-transform: uppercase;
    /* Text - med grey */
    margin: 0;
    color: #9f9f9f;

    * {
      margin: 0;
      font-size: small;
    }
  }
  .article-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #2b2b2b;
  }

  .enquiry {
    font-family: Work Sans;
    font-style: normal;
    line-height: 20px;
    cursor:default;
    /* or 125% */

    color: #046db6;
    h6 {
      width: 50%;
      font-size: medium;
      font-weight: 700;
      @media (min-width: 768px) {
        width: 25%;
      }
    }
  }

  hr {
    // margin: 0;
    border: 1px solid #000000;
  }

  .similar-articles {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    h1 {
      line-height: 45px;
    }
    /* or 112% */

    letter-spacing: 1px;

    color: #000000;
  }
}
.ellipse{
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}


.image-container {
  .image {
    height: 200px;
    img {
      object-fit: contain;
    }
  }
}

.hoverlist:hover{
   text-decoration: underline;

}
