.wrapper {

  margin: 0 auto;
}

.accordion-wrapper {
  &+* {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}


.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 400;
  font-family: work sans;
  color: #1d262e;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  border: solid 1px #1d262e;

  &::before {
    content: "";
    width: 0;
    height: 0;

  }

  &:hover,
  &.open {
    color: black;
  }

  &.open {
    &::before {
      content: " - ";
    }
  }
}

.accordion-content {
  padding: 0.0em 0.5em;
}

.items {
  color: #9f9f9f
}

.items:focus {
  color: #f15d22;
  font-size: 1rem;
}

// .items:focus-within {
//   color: #f15d22 !important;
//   font-size: 1rem;
// }

p:hover {
  color: black;
}
