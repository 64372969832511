.card {
  // background-color: #EFEFEF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  // background-color: #efefef !important;
}

//chrome
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//mozilla
input[type=number] {
  -moz-appearance: textfield;
}

@media(max-width:540px) {
  .cheque-mt {
    margin-top: 20px;
  }
  .heading-bold{
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 20px;
  }
}

// label{
//     white-space: nowrap !important;
// }
