.select-custom {
  &__control {
    background: transparent !important;
    border: 1px solid #1d262e !important;
    box-shadow: none !important;
  }
  &__indicator,
  &__placeholder {
    color: black !important;
  }

  &__indicator-separator {
    background: black !important;
  }
  // &__menu {
  //   width: 135% !important;
  // }
}

.option {
  font-size: small !important;
}
