// @import "../../../index.scss";

.ipsDiv {
  min-height: 50vh;
  .login-modal {
    @media (min-width: 768px) {
      width: 50%;
    }
    .sign-up-container {
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;

      .close-modal {
        border-radius: 0;
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
  }
}
