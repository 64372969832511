* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h1 {
    text-align: center;
    margin: 2rem 0 4rem 0;
  }

.accordion_item{
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #efefef;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
  .accordion_title {
    /* display: flex;

    flex-direction: row;
    justify-content: space-between; */
    /* padding-top: 5px; */
    width: 100%;
    border: none;
    cursor: pointer;
  }


  .accordion_content {
    /* padding: 5px; */
   width: 100%;
   border: none;
  }

  .accordion_content {
    background-color: #ffffff;
  }

  @media screen and (max-width: 700px) {
    body {
      font-size: 18px;
    }
    .accordion {
      width: 90%;
    }
  }

  .labelss{
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    /* margin-bottom: 16px; */
}

.accordion_subitem{
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #efefef;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
