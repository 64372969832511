.fromMargin{
    margin-left: 7px !important;
}

.card{
    background-color: #EFEFEF;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}
.top10{
    margin-top: 10px;
}
.err{
    color: red;
}
.suc{
    color: green;
}
.disabledTextBox{
    background-color: gainsboro;
}

.paddingLeft{
    padding-left: 0px !important;
}
.padding10{
    padding: 10px !important;
}
.top10{
    margin-top: 10px;
}

.margin-left-5 {
    margin-left : 5px;
}
.margin-left-10 {
    margin-left : 10px;
}
.margin-left-15 {
    margin-left : 15px;
}
.margin-left-20 {
    margin-left : 20px;
}
.margin-top-15 {
    margin : 15px 0px;
}
.createAgency {
    color: #0000EE;
    cursor: pointer;
  }

// .CustomModal{
//     width: 40% !important;
// }
.top-10 {
    margin : 10px !important;
}
.btn{
    width: 20%;
}

.table-header {
    background-color: #efefef;
}


tr:hover{
    background-color: #efefef;
}
.underline {
    text-decoration : underline !important
}
.float-left {
    float: left;
}
.clear-float{
    clear: both;
}
.active{
    color: #2E8B57;
    // font-size: 20px;
    font-weight: 600;
}
.margin-left-35 {
    margin-left : 35px;
}
