@import "../../../../index.scss";

.user-profile {
  padding: 1rem 0rem;
  background: #efefef;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

  @media (min-width: 768px) {
    padding: 1rem 3rem;
  }

  .user-profile-form {
    .form-control {
      border-radius: 0;
      border: none;
      border-bottom: none !important;

      &[readonly] {
        background-color: white !important;
      }
    }
  }

  .marketing-promotion {
    color: #000000;
    .heading {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 10;
      line-height: 36px;
      color: #000000;
      overflow-wrap: break-word;
    }

    .heading-content {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
    }


  }

  .disclaimer {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;

    .next-button {
        background: #f15d22;
        margin-left: auto;
        order: 2;
        padding: $spacing-2x $spacing-3x;
        border: none;

        .button-label {
          font-family: Work Sans;
          font-style: normal;
          font-weight: bold;
          font-size: $spacing-3x;
          color: white;
          line-height: 19px;
          text-align: center;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
      .next-button-disabled{
        background: #a4a1a1;
        color:#000000
      }
  }
}



.usetabdisabled{
  .react-tabs__tab--selected {
    border-bottom: #ffff !important;
  }
}

.padinglabel{
  padding-left: 30px !important;
}

.breskk{
  overflow-wrap: break-word;
}
