// @import "../../../index.scss";
@import "../../../assets/global/variables";

.subscription-container {
  background: #efefef;

  .subs-content {
    background: #59b3e4;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: normal;
    line-height: 45px;
    letter-spacing: 1px;
    color: #242f39;
    padding: $spacing-2x;

    .preferred {
      position: absolute;
      top: -0.5rem;
      background: #f15d22;
      border-radius: 13px;
      padding: 0 10px;
      height: 40px;
      color: white;
      font-weight: bold;
      font-family: poppins;
      left: 50%;
      transform: translate(-50%,-50%);

      // @media (min-width: 576px) {
      //   left: 40%;
      // }
      // @media (min-width: 768px) {
      //   left: 30%;
      // }
    }

    .sub-pack-container {
      padding: $spacing-3x;
      min-height: 350px;
      background: #ffffff;
      border-radius: 23px;
      // margin: 1rem 2rem;

      .sub-fee {
        padding-bottom: 2px;
        font-weight: 600 !important;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 68px;

        @media (max-width:280px) {
          margin-left: -18px;

        }

      }

      .dollor {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 49px;
      }

      .fee-yr {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 43px;
      }

      .sub-price {
        font-weight: 600 !important;
        font-family: 'Work Sans';
        font-style: normal !important;
        font-size: 18px;
      }

      @media only screen and (min-width:912px) {
        padding: $spacing-2x;
      }

      .sub-type {
        padding-bottom: $spacing-1x;

        h5 {
          color: #f15d22;
        }

        h4 {
          color: #f15d22;
        }
        @media (max-width: 286px) {
          h4 {
          font-size: 1.2rem !important;      }}
      }

      hr {
        border: 1px solid #000000;
        width: 100%;
        margin-top: 0;
      }

      .sub-text {
        font-size: $spacing-2x - 1px;
        // font-weight: 600;
        font-family: bold;
        // @media (max-width:768px){
        display: inline-block;
        // }

      }

      .select-button {
        background: black;

        @media(max-width:768px) {
          width: 100%;

        }

        .button-label {
          font-family: Work Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          color: white;
          line-height: 19px;
          text-align: center;
          letter-spacing: 2px;
          text-transform: uppercase;
          @media(max-width:272px) {
            font-size: 8px;
            letter-spacing: 1px;

          }
        }
      }
    }
  }

  .pkg-sub {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    line-height: 45px;
    letter-spacing: 1px;
    color: #242F39;

  }

  .mark-promo-container {
    padding: $spacing-2x;

    .heading {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 1px;
      color: #000000;
    }

    .sub-heading {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }

    .checkbox-element {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: $spacing-2x;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 1px;
      color: #000000;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
          ~ {
            .checkmark {
              background-color: #242f39;

              &:after {
                display: block;
              }
            }
          }
        }
      }

      .checkmark {
        &::after {
          left: 9px;
          top: 2px;
          width: 7px;
          height: 15px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      border-radius: 25px;
      width: 25px;
      border: 1px solid #242f39;

      &::after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    .tips {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }

    .next-button {
      margin-left: auto;
      order: 2;
      padding: $spacing-2x $spacing-3x;

      &:hover {
        .button-label {
          color: #1d262e;
        }
      }

      .button-label {
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        font-size: $spacing-3x;
        color: white;
        line-height: 19px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width:280px) {}
}

.checkboxlevel {
  padding-top: 16px;
  padding-right: 7px;
}
