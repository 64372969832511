.select-control {
    border: 1px solid #1d262e;
    background: transparent;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;

    &.white {
      background-color: white;
    }
  }
