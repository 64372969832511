@import "../../../../index.scss";
.subs-details {
  background: #efefef;
  min-height: 400px;
  .subscription-plan {
    padding: 1rem;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    min-height: 150px;
    @media (min-width: 768px) {
      padding: 1rem 3rem;
    }

    .heading {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }

    .sub-display {
      background: #59b3e4;
      border-radius: $spacing-2x;
      padding: 1rem;
      margin-top: 1rem;

      .heading {
        background: #1d262e;
      }
      .sub-data {
        color: #242f39;

        .sub-name {
          font-family: Work Sans;
          font-style: normal;
          float: left;
          font-weight: 600;
          font-size: 24px !important;
          line-height: 34px;
          white-space: normal;
          @media (max-width:340px){
            font-size: 20px !important;
          }
          @media (max-width:290px){
            font-size: 18px !important;
          }
        }
        // .sub-type {
        //   font-size: 17px;
        // }

        .extras {
          font-family: Work Sans;
          font-style: normal;
          // font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          color: #242f39;
        }

        .fees {
          font-family: Work Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
          color: #242f39;
          // word-break: break-all;

        }
        .sub-amount-content {
          @media (min-width: 768px) {
            float: right;
          }
          @media (max-width: 300px) {
           font-size: 27px;
          }
          .sub-amount {
            font-weight: 600;
            color: #242f39;
            sub {
              font-size: 50%;
              @media (max-width:340px){
                font-size: 18px !important;}
            }
            @media (max-width:340px){
              font-size: 18px !important;

            }
          }
        }

        .hr-line {
          border: 1px solid #242f39;
        }
      }
    }
  }
  .past-subscription-plan {
    padding: 1rem;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    @media (min-width: 768px) {
      padding: 1rem 3rem;
    }

    .heading {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }
  }
}

.inlinedivs{
  @media (max-width: 450px) {
    display:block;
  }
}

.freefl{
    @media (min-width: 771px) {
    //  padding-left: 70px;
    }

}

.freefl{
  @media (min-width:450px)  and (max-width: 770px)  {
  //  padding-left: 70px;
  }

}
.unlimitescss{
  @media (max-width: 285px) {
   display: block !important;
   }
   @media (max-width: 340px) {
    font-size: 18px !important;
    }
    @media (max-width:290px){
      font-size: 16px !important;
    }
}
.ccs{
  font-size: 20px !important;
  font-family: Work Sans;
  font-weight: 600 !important;
  line-height: 23px !important;
  padding-top: 0px !important;
  @media (max-width:340px){
    font-size: 18px !important;
  }
  @media (max-width:290px){
    font-size: 16px !important;
  }
}
.tohidespan{
  @media (max-width:365px){
    display: none;
  }
}
.subclasscss{
  @media (max-width:340px){
    font-size: 18px !important;}

}

.supclasscss{
  @media (max-width:340px){
     padding-left: 15px !important;
  }

}
