// @import "../../../index.scss";
.login-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

  .logo {
    width: 50%;
    text-align: center;

    @media (min-width: 768px) {
      width: 40%;
    }
  }

  .login-headings {
    font-weight: 500;
    color: #1d262e;
    float: left;
    @media (max-width:310px){
      font-size: 22px !important;
     }
  }

  .form-control {
    padding-left: 0;
    border: none !important;
    border-bottom: 1px solid #1d262e !important;
    border-radius: 0 !important;
    color: #1d262e !important;
  }

  .form-control:focus {
    color: #1d262e !important;
    background-color: #fff !important;
    border-bottom: 1px solid #1d262e !important;
    border-color: #1d262e !important;
    outline: 0 !important;
    box-shadow: none !important;
  }

  .forgot-password {
    color: #f15d22;
    cursor: pointer;
   @media (max-width:372px){
    display: flex;
    white-space: nowrap;
   }
   @media (min-width:768px) and (max-width:908px) {
    display: flex;
    white-space: nowrap;
   }
   @media (min-width:912px) and (max-width:996px) {
    display: flex;
    white-space: nowrap;
   }
   @media (max-width:310px){
    font-size: 11px !important;
   }

    &:hover {
      text-decoration: underline;
    }
  }

  .login-button {
    border-radius: 0;
    padding: 6px 24px;
  }
}

.frgotpwd {
  white-space: nowrap;
  font-size: 0.7rem;
}

@media (max-width: 540px) {
  .login-modal {
    width: 85% !important;
    top: 50% !important;
    z-index: 9999;
  }
}

@media (max-width: 767px) {
  .login-modal {
    width: 85% !important;
    top: 30% !important;
    z-index: 9999;
  }
}

@media (min-width: 768px)and (max-width:912px) {
  .login-modal {
    width: 50% !important;
    top: 30% !important;
    z-index: 9999;
  }
}
