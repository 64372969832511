@import "./assets/global/mixins";
@import "./assets/global/variables";
@import "~bootstrap/scss/bootstrap";
@import 'react-tabs/style/react-tabs.css';

@font-face {
  font-family: "Work Sans";
  src: url("./assets/fonts/WorkSans-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Work Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  min-width: 400px;
}

.font-medium {
  font-size: medium;
}

::-webkit-scrollbar {
  width: 8px;
  background: #9F9F9F;
border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EFEFEF;
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9F9F9F;
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// .form-control {
//   border: none !important;
//   border-bottom: 1px solid #1d262e !important;
//   border-radius: 0 !important;
//   color: #1d262e !important;

//   &::placeholder {
//     color: #1d262e;
//     font-weight: 500;
//     opacity: 1; /* Firefox */
//   }
//   &:focus {
//     color: #1d262e !important;
//     background-color: #fff !important;
//     border-bottom: 1px solid #1d262e !important;
//     border-color: #1d262e !important;
//     outline: 0 !important;
//     box-shadow: none !important;
//   }
// }

.spinner-border {
  width: 6rem !important;
  height: 6rem !important;
  &.text-primary {
    color: #f15d22 !important;
  }
}

// input:-internal-autofill-selected {
//   background-color: transparent !important;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    // color: white !important;
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;

}

.error-message {
  color: red;
  padding-top: $spacing-1x;
}

.grecaptcha-badge {
  display: none !important;
}
.table th {
  text-align: center !important;
  white-space: nowrap !important;
}
 td {
  white-space: nowrap !important;
}
.custom-mt-1{
  margin-top : 0.25rem !important
}

.descriptionWrap{
  display: block;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.overflowScroll-X{
  overflow-x: scroll;
}
.card{
  padding: 20px !important;
}

table{
  background-color: #FFF;
}


////////////////////////////
///

.container-top {
  --offset: 100px;
  --fade: 120px;

  display: flex;
  width: 60px;
  justify-self: end;
  justify-content: flex-end;
  align-items: flex-end;
  --webkit-mask:linear-gradient(#0000 calc(100vh + var(--offset)),#000 calc(100vh + var(--offset) + var(--fade)));
}

.top {
  // position: sticky;
  position: absolute;
  bottom: 20px;
  margin-right:10px;

  /* visual styling */
  // width:100%;
  width:50px;
  background:#f15d22;
  border-radius:10px;
  z-index: 1000;
  aspect-ratio: 1;
}
.top:before {
  content:"";
  position:absolute;
  inset:30%;
  transform:translateY(20%) rotate(-45deg);
  border-top:5px solid #fff;
  border-right:5px solid #fff;
}


/* remove the below if you don't want smooth scrolling */

.ttt{
  // position: absolute !important;
  // bottom : 100% !important;
  // right : 0% !important;
}

.myBtn {
  // display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #f15d22;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.isonline {
  position: fixed;
  left: 25%;
  top: 10px;
  width: 50%;
  // background-color: red;
  // color: white;
  text-align: center;
  // background: red;
  z-index: 1;
}
@media (max-width: 450px) {
  .isonline  {
    left: 0;
    width: 100%;
  }
  .alert-dismissible{
    padding-right : 0rem;
  }
  .margin-top-55px{
    margin-top: 55px;;
  }
}
.alert-custom{
  border-radius: 1.25rem !important;
}


.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 900ms, opacity 900ms;
}
.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 900ms;
}
