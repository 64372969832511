.aboutus{
 padding-left: 5%;
 padding-right: 5%;
 font-family: 'Work Sans';
}


.faq{
    background-color: #efefef;
    min-height: 45px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    position: relative;
}
.verticalcenter{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
}

.arrow{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    left: 90%;
    margin-right: 15px;
}

.innerdiv{

}

h6{
font-family: Work Sans;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: 45px;
letter-spacing: 1px;
text-align: left;
}

.faq2{
    background-color: #f3eaea;
}


    @media (max-width: 520px){
        .buttonlongmobile{
        width:100%;
        }
    }


.accordion{
    width: auto !important;
}
