// .CustomModal {
//     position: fixed;
//     z-index: 100;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     margin: 0 auto;
//     box-sizing: border-box;
//     transition: all 0.3s ease;
//     // height: auto;
//     width: 100%;
//     // height: 75%;

//     // @media (min-width: 768px) {
//     //   width: 75%;
//     // }

//     .close-icon-container {
//       padding: 0px 16px;

//       .close-icon {
//         z-index: 1;
//         color: white;
//         height: 24px;
//       }

//       .icon-img {
//         float: right;
//         width: 20px;
//        color:white;

//       }
//     }

//     .modal-content {
//       padding: 16px;
//     }
//   }
.detail-modal {
  width: 70% !important;
}

@media (max-width: 670px) {
  .detail-modal {
    width: 80% !important;
    height: 80% !important;
    font-size: 10px;
  }

}

@media (max-width: 575px) {
  .dheader {
    font-weight: 500 !important;

  }

  .detail-modal {
    width: 90% !important;
    height: 90% !important;
    font-size: 6px;
  }

}

@media (min-width:576px) and (max-width:912px) {
  .dheader {
    font-weight: 500 !important;
  }
  .detail-modal {
    width: 90% !important;
    height: 90% !important;
    font-size: 10px;

  }
}

.dheader {
  font-weight: 500;
}

.demail {
  overflow-wrap: break-word;
}
