.frame {
    background: #d9eaf5 !important;
    padding: 0 !important;
    border: 1px solid #0072BA;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.frame-top {
    background: #0072BA;
    color: #FFF;
    padding: 20px 30px;
    // -webkit-border-radius: 10px 10px 0 0;
    border-radius: 10px 10px 0 0;
}

.frame-text {
    padding: 20px 40px;
}

.sec-frame {
    height: 38px;
    // background: url(../images/sec-line.jpg) no-repeat;
    background-position: center center;
}

.enquiry_title {
    padding-left: 20px;
    padding-top: 20px;
}


// @media only screen and (min-width: 768px) {

.section1,
.section2,
.section3,
.section4 {
    background: #0072BA;
    text-align: center;
    margin-left: 0px !important;
}

// }
// .section {
//     line-height: 1.5;
// }
.section {
    height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    line-height: 0.9;
    padding: 10px;
}

.label-text {
    padding: 10px 0;
}

.inputbox {
    // width: 100%;
}

.btn-color {
    background: #0072BA !important;
    border-radius: 10px;
    font-family: Arial;
    color: #ffffff !important;
    font-size: 20px;
    padding: 5px 15px;
    margin: 30px 0;
    text-decoration: none;
    // float: right;

}

.btn-submit {
    text-align: center !important;
}

.inputbox {
    width: 90%;
    padding: 2px;
    margin: 5px;
}

.text-head {
    position: relative;
    background: #F3F3F3;
}

.textarea-width {
    position: relative;
    width: 100% !important;
}

.elable {
    padding-left: 30px !important;
}

.err {
    color: red;
    font-weight: 700;
}

.suc {
    color: green;
    font-weight: 700;
}

@media only screen and (min-width: 320px) {
    h1 {
        font-size: 18px;
    }
}

@media (min-width: 572) {
    .enquiry_title {
        padding-left: 20px !important;
        padding-top: 20px;
    }
}

@media (max-width: 992px) {
    .enquiry_title {
        padding-left: 80px;
        padding-top: 20px;
    }
}

@media only screen and (min-width: 992px) {
    .frame {
        left: 25%;
    }
}

@media only screen and (max-width: 1024px) {
   .enquiryTitle{
    padding-left: 10px;
   }
}

@media only screen and (max-width: 280px) {
   .errorMessage{
    font-size: 10px;
   }
}

@media (max-width: 540px) {
    .enquiry-modal {
      width: 85% !important;
      top: 50% !important;
    }
  }

  @media (max-width: 767px) {
    .enquiry-modal {
      width: 85% !important;
      top: 50% !important;
      font-size: 10px;
    }
  }

  @media (min-width: 768px)and (max-width:912px) {
    .enquiry-modal {
      width: 50% !important;
      top: 40% !important;
    }
  }
