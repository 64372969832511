
.bdy { text-align: center; padding: 0; color: #000;  }
.h11 { font-size: 50px; font-weight: 100; text-align: center;}
.bdy { margin-top: 10px !important;
    display: -webkit-box; display: -ms-flexbox; display: flex;
    -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
    -webkit-box-align: center; -ms-flex-align: center; align-items: center;}
.articles { display: block; width: 700px; padding: 50px; margin: 0 auto; }

.ftr{
    margin-top:10px;
    background-color: #242f39;
    height: 1px;
    width: 100%;
}
