@import "../../../../assets/global/variables";

.heading {
  font-weight: 300;
}

.names {
  font-family: 'work sans';
  font-weight: 900;
  color: #1d262e;

}

.react-tabs__tab--selected {
  display: inline-block;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 3px solid #f15d22;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;

}

.nl_link {
  // color: #0000EE;
  text-decoration: underline;
  text-decoration-color: #242f39;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
  color: #007bff;
}

.nl_article_access {
  margin-top: -6px !important;
}

.nl_p_inline {
  padding-inline-end: 60px !important;
}

.card {
  // background-color: #EFEFEF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  // background-color: #efefef !important;
}

.react-tabs__tab-list {
  // border-bottom: 1px solid #aaa ;
  margin: 0 0 10px;
  padding: 0;
  // display: flex;
  // white-space: nowrap;
}

.react-tabs__tab--selected {
  display: inline-block;
  // border-right: 3px solid #f15d22;
  // border-left: 3px solid #f15d22;
  // border-top: 3px solid #f15d22;
  border-bottom: 3px solid #f15d22 !important;
  // bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.spinner {
  position: fixed;
  left: 0px;
  top: 25%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}


@media (min-width: 765px) {
  .top-padding {
    padding-top: 2.2rem !important;
  }
}
