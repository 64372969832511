@import "../../../assets/global/variables";

* {
    margin: 0;
    padding: 0;
}

.line {
    @media (min-width: 760px) {

        border-right: 9px solid #c4c4c4;

    }

}

.hidemenu {
    display: none;

}

.arrow {
    position: fixed;

    top: 350px;

}

.mt-10 {
    margin-top: 10px;
}

.margin-left-10 {
    margin-left: 10px;
}

@media (max-width: 768px) {
    .toggleIcon {
        background: #242f39;
        z-index: 999999 !important;
    }
}

@media (min-width: 769px) {
    .toggleIcon {
        display: none;
    }
}
