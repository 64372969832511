.button-control {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold !important;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;

  &.transparent-orange {
    border: 1px solid #f15d22;
    color: #f15d22;
  }

  &.transparent-black {
    background-color: transparent !important;
    color: #1d262e;
    border: 1px solid #1d262e;
  }

  &.background-orange {
    background: #f15d22;
    border: 1px solid #f15d22;
    color: #ffffff;

    &:hover {
      background: transparent;
      color: #1d262e;
      border: 1px solid transparent !important;
    }
  }
  &:hover {
    border: 1px solid currentColor !important;
  }
}
