@import "../../../../../assets/global/variables";

* {
    margin: 0;
    padding: 0;
}

.heading {
    font-weight: 300;
    padding-top: 5px;
}


.line {
    @media (min-width: 760px) {

        border-right: 9px solid #c4c4c4;

    }

}

.create-acc {
    padding-top: 20px;
}


.form-control-content {



    .form-control-label {
        white-space: nowrap;
        border-bottom: 1px solid #1d262e;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
        margin-bottom: 16px;

    }


}

.checkboxlabel {

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
}

.flexcheckbox {
    flex-basis: 190px;
}

.basisflex {
    flex-basis: 190px;
}

input.transparent {
    background-color: #efefef !important;
}

input.cls {
    background-color: white !important;

}

.sortorder {
    font-weight: 500;
    color: #000000;
}

.right {
    float: right;
}

.selectlist-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.selectlist-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.date-item {
    overflow: visible;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.labels {
    // white-space: nowrap;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;

}

.dates {
    overflow: visible !important;
}

.card {
    // background-color: #EFEFEF;
    border-radius: 5px;
    // margin: 2px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.loader {
    position: fixed;
    left: 50%;
    top: 50%;
    // width: 100%;
    // height: 100%;
    z-index: 9999;
}

.sortOrder {
    margin-right: 30px;
}

.sortorderp {
    padding: 10px;
}

@media (max-width: 394px) {
    .sortorderp {
        padding: 0px !important;
    }

    .sortorder-padding {
        padding-top: 0rem !important;
        padding-right: 1.5rem !important;
        padding-bottom: 0rem !important;
        padding-left: 0rem !important;
    }

    .sortorder-width {
        width: 200px !important;
        margin-top: 15px !important;
    }

    .sort-margin-top {
        margin-top: -15px !important;
    }

    .margin-top {
        margin-top: 15px !important;
    }

    .margin-top-radio {
        margin-top: -10px !important;
    }
}

.sortorder-padding {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
}

.ws-nowrap {
    white-space: nowrap;
}

.card-margin-left-0px {
    margin-left: 0px !important;
}

.note {
    color: red;
    font-size: medium;
}

@media(max-width:540px) {
    .format-mt {
        margin-top: 20px;
    }

    .heading-bold {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 20px;
    }

}
