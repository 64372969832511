.editor{
    height: 250px;
    border: 1px solid rgb(90, 89, 89);
    margin: 10px;
    overflow-y: scroll;
}
// .login-modal {
//     width: 40% !important;
// }
@media (min-width: 376px) {
    // .login-modal{
    //     width: 80% !important;

    // }
    .selectLang{
        margin: 10px;
    }
}
@media (min-width: 415px) {
    // .login-modal{
    //     width: 80% !important;
    // }
    .selectLang{
        margin: 10px;
    }
}
@media (min-width: 768px) {
    // .login-modal{
    //     width: 80% !important;
    // }
    // .CustomModal {
    //     width: 80% !important;
    // }
}
.loader{
    position: fixed;
    left: 50%;
    top: 50%;
    // width: 100%;
    // height: 100%;
    z-index: 9999;
}

.mr10{
    margin-right : 10px;
}

.ml0{
 margin-left: 0px !important;
}
