.table-container {
  background: #ffffff;
  padding: 8px;

  .table {
    // background: #ffffff;
    table-layout: auto;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    line-height: 26px;
    color: #000000;

    th {
      border-top: 0;
    }

    .table-header {
      border-bottom: 2px solid #000000;
    }
  }
}

.decorline {
  text-decoration: underline;
  text-decoration-color: #242f39;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;


}

.incrSize {
  width: 22% !important;
}

.text_danger {
  color: red
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  // width: 100%;
  // height: 100%;
  z-index: 9999;
}

.card {
  // background-color: #EFEFEF;
  border-radius: 5px;
  // margin: 2px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-top-15 {
  margin: 15px 0px;
}

.margin-top-10 {
  margin: 10px;
}

.marginTop-10 {
  margin-top: 10px;
}

.paddingLeft {
  padding-left: 0px !important;
}

.padding10 {
  padding: 10px !important;
}

.top10 {
  margin-top: 10px;
}

.bottom10{
  margin-bottom: 10px;
}

.padding10 {
  padding: 10px;
}
.margin2px{
margin-left: 1px !important;
}
@media only screen and (max-width: 1024px) {

}
