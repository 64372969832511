.custom-switch .custom-control-label::before {
  top: 0.7rem !important;
  width: 6rem !important;
  height: 0.4rem;
  background-color: #242f39 !important;
}
.custom-switch .custom-control-label::after {
  width: calc(1.4rem - 4px) !important;
  background-color: #242f39 !important;
  height: calc(1.4rem - 4px) !important;
  left: calc(-1.8rem + 2px) !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #242f39 !important;
  border-color: transparent !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: #dddddd !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  left: 1rem !important;
}

.on-label {
  margin-left: (1rem/2);
}
.off-label {
  margin-left: 2rem;
}

.on-label,
.off-label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #1d262e;

  &.inactive {
    color: #9f9f9f;
  }
}
