// @import "../../../index.scss";
@import "../../../assets/global/variables";
@import "../../../assets/global/mixins";

.dashboard {
  background: #efefef;

  .toLabel {
    font-family: "Work Sans";
    color: #1d262e;
  }

  .search-container {
    padding: $spacing-1x;

    .search-input {
      background: transparent;
      border: none;
      border-bottom: 1px solid #9f9f9f;
      outline: none;
      color: #9f9f9f;
    }

    .search-icon {
      position: absolute;
      top: $spacing-1x;
      left: 1px;
      color: #9f9f9f;
    }
  }

  .select-control {
    border: 1px solid #1d262e;
    background: transparent;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
  }

  .search-button {
    background: #f15d22;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .advanced-search-text {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    @include cf(#1d262e, medium);
  }

  .dashboard-info-container {
    background: url("../../../assets/images/dashboard-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-family: "Lato";

    @media (min-width: 768px) {
      padding: 75px;
    }

    .info-container {
      background: rgba(36, 47, 57, 0.8);
    }
  }

  .announcements-container {
    background: #242f39;
    color: #ffffff !important;

    ul {
      font-size: 13px;
      line-height: 1.5em;
      margin: 5px 0 15px;
      padding: 0;

      li {
        list-style: none;
        position: relative;
        padding: 0 0 0 20px;

        // span {
        //   color: #ffffff !important;
        // }
      }
    }

    ul.square {
      li {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 10px;
          height: 10px;
          background-color: red;
        }
      }
    }

    .container-heading {
      font-family: "Work Sans";

    }

    .content {
      font-family: "Lato";

      p,
      span {
        color: white !important;
      }
    }
  }

  @media (max-width:480px) {
    .mobileContentType {
      display: none !important;
    }

  }
}


.sgtinput {
  @media (max-width :768px) {
    padding-bottom: 15px !important;
    padding-left: 15px;

    .search-button {
      width: 100% !important;
    }

  }
}

@media (max-width :576px) {

  .search-button {
    width: 100% !important;
    // padding: 0!important;
    margin: 0 !important;
    position: relative;
  }

  .ad-search-link {
    padding-top: 15px;
    text-align: center;
  }
}

.conterhead {
  word-wrap: break-word;

  @media (max-width:575px) {
    font-size: 23px;
  }
}

@media (max-width :768px) {
  .datealign {
    padding-left: 15px !important;
  }

  .sgtinput {
    width: 100% !important;
  }
}
