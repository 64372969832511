@import "../../../../index.scss";

.usage-report {
  padding-bottom: 2.2rem;
  background: #ffff;
  .usage-container {
    background: #efefef !important;
    padding: 1.5rem;

    .heading {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600;
      line-height: 35px;
      // float: left;
      letter-spacing: 1px;
      color: #1d262e;
    }

    // .toLabel {
    //   float: right;
    // }
  }
}

// .table-container{
//   background: #efefef !important;
// }
// .table{
//   background: #efefef !important;
// }
// .table-header{
//   background-color: white !important;
// }
