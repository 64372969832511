.users-container {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    padding: 1rem;

    .heading {
      font-weight: 300;
    }

    .users-table {
      padding-top: 2rem;
      .table-header {
        background: #EFEFEF;
      }
    }
  }

  .pendingSub{
    color:#000080;
    // font-size: 20px;
    font-weight: 600;
  }
  .inactiveSub{
    color:#B8860B;
    // width:20px;
    // font-size: 20px;
    font-weight: 600;
  }
  .activeSub{
    color: #2E8B57;
    // font-size: 20px;
    font-weight: 600;
  }
  .closeSub{
    color:red;
    // font-size: 20px;
    font-weight: 600;

  }
  .suspended{
color: #d336a4;
// font-size: 20px;
font-weight: 600;
  }
  .upgraded{
    color: #32CD32;
// font-size: 20px;
    font-weight: 600;
  }
  .Deleted{
    color: #800000;
    // font-size: 20px;
        font-weight: 600;
  }
