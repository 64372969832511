.checkbox-element {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: #242f39;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    &::after {
      left: 9px;
      top: 2px;
      width: 7px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  border-radius: 25px;
  width: 25px;
  border: 1px solid #242f39;
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}
