// @import "../../../index.scss";
@import "../../../assets/global/variables";
.subscription-form-container {
  background: #efefef;

  .subs-form {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: normal;
    letter-spacing: 1px;
    color: #242f39;
    padding: $spacing-2x;

    .form-control-content {
      border-bottom: 1px solid #1d262e;

      .form-control-label {
        white-space: nowrap;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
      }

      .form-control {
        background-color: transparent;
        border: none !important;

        &:focus {
          background-color: #fff;
          box-shadow: none !important;
        }
      }
    }

    .select-control {
      background: transparent !important;
    }

    .order-summary-section {
      .summary-heading {
        font-family: Work Sans;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
        color: #1d262e;
      }

      .summary-card {
        padding: 1.5rem;
        background: #ffffff;
        border-radius: 23px;
        width: 100%;
        overflow: auto;
        .plan-name {
          color: #f15d22;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          margin-right: 1rem;
          font-size: 1.2rem;
          white-space: normal;
          @media (min-width:768px) and (max-width: 867px) {
            font-size: 1rem;
        }
          @media (max-width: 410px) {
            font-size: 1rem !important;
            width: 100% !important;
            padding-bottom: 5px;
        }


        }
        .plan-price {
          font-size: 1.2rem;
          white-space: normal;

          @media (min-width:768px) and (max-width: 867px) {
            font-size: 1rem;
        }
          @media (max-width: 410px) {
              font-size: 1rem;
              width: 100%;

          }
          @media (max-width: 322px) {
            font-size: 0.8rem;
            float: none !important;
        }

          sup {
            top: -0.2em !important;
          }
        }
        hr {
          border: 1px solid #000000;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .fee-header {
          font-size: 0.7rem;

          @media (min-width: 768px) {
            font-size: 1.25rem;
          }
        }

        .fee {
          font-family: Work Sans;
          font-style: normal;
          color: #000000;
          font-size: 0.7rem;

          @media (min-width: 768px) {
            font-size: 1.25rem;
          }
        }

        .payment-type {
          .payment-heading {
            font-size: 1.13rem;
          }
        }
      }

      .button-control {
        border-radius: 0;
        padding: 0.25rem $spacing-4x  !important;
      }
    }

    .payment-info {
      .card-type {
        height: 50px;
        width: 100px;
      }

      .checkmark {
        top: 10px !important;
      }
    }
  }
}

@media (max-width: 540px) {
  .forgot-password-modal {
    width: 85% !important;
    top: 50% !important;
  }
}

@media (min-width: 768px)and (max-width:912px) {
  .forgot-password-modal {
    width: 50% !important;
    top: 50% !important;
  }
}

@media (max-width: 400px) {
  .lable-xs{
    font-weight: bold !important;
    font-size : 30px !important;
  }
}

.inputdown{
  width: 100%;
  padding-top: 20px !important;


}
.inputdown1{
    width: 100%;
    padding-top: 20px !important;

}
