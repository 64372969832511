@import "../../../assets/global/variables";
@import "../../../assets/global/mixins";
/* Popover styling */

a {
  text-decoration: none;
}

.popover__title {
  font-size: 15px;
  line-height: 36px;
  text-decoration: none;
  /* color: rgb(228, 68, 68); */
  text-align: center;
  padding: 15px 0;
}

.popover__wrapper {
  position: relative;
  /* margin-top: 0.5rem; */
  display: inline-block;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50px;
  transform: translate(0, 10px);
  background-color: #bfbfbf;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
  height:auto;
  max-height: 350px;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  @media (max-width:600px){
    text-align: left !important;
    width: 85% !important;
  }
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #bfbfbf transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__wrapper .popover__content {
  z-index: 10;
  visibility: visible;
  transform: translate(0, -20px);
  /* transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); */

}

.popover__message {
  text-align: center;
}

.openpop {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.nonopenpop {
  display: none;
}

.con {
  /* ... */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-field {

  height: auto;
  min-height: 34px;
  border: 2px solid #737679;
  padding: 5px;
  /* margin: 8px; */
  cursor: pointer;
  border-radius: 3px;

  box-shadow: 0 2px 6px rgba(25, 25, 25, 0.2);
}

.form-field .chips .chip {
  display: inline-block;
  width: auto;

  background-color: #bfbfbf;
  color: #bfbfbf;
  border-radius: 3px;
  margin: 2px;
  overflow: hidden;
}

.form-field .chips .chip {
  float: left;
}

.form-field .chips .chip .chip--button {
  padding: 8px;
  cursor: pointer;
  background-color: #bfbfbf;
  display: inline-block;
}

.form-field .chips .chip .chip--text {
  padding: 8px;
  cursor: no;
  display: inline-block;
  pointer-events: none
}


.close {
  font-size: 20px;
  margin: 2px;
  padding-left: 15px;
}


.badge-light {
  background-color: #bfbfbf;
}


.iconcss {
  position: relative;
}

.hoveffect {
  &:hover {
    background-color: #e1dedf;
    color: #8f8b8b;
  }
}

.selectlist {
  background-color: #e1dedf;
  color: #8f8b8b;
}

.search-container2 {
  border-bottom: 1px solid #9f9f9f;

  // padding: $spacing-1x;
  .search-input2 {
    background: transparent;
    border: none;
    outline: none;
    color: #9f9f9f;
    width: auto !important;
  }

  .search-icon {
    position: absolute;
    top: $spacing-1x;
    left: 1px;
  }

  .cross-icon {

    // top: $spacing-8x !important;
    color: #b4afaf;
    z-index: 99 !important;
    cursor: pointer;
  }

  .cross-icons {

    // top: $spacing-8x !important;
    color: #b4afaf;
    z-index: 99 !important;
    opacity: 0;


  }

  @media (max-width: 576px) {

    text-align: center !important;

    .btnwdth {
      width: 100% !important;
    }
  }
}
@media (max-width:768px) {
  .suggestwdth{
    width: 85%;
  }
  .inptwdth1{
    width: 100% !important;
  }
}
.hidecrossicon {
  @media (max-width:286px) {
    display: none;
  }
}
