@import "../../../assets/global/variables";
.search-response {
  .content-types,
  .simple-search,
  .filter-container {
    // display: none;

    @media (min-width: 768px) {
      display: inline-block;
    }
  }
  .search-display-container {
    background: #ffffff;

    .filter-modal {
      height: 100%;
      overflow: scroll;
      width:90%;
    }

    .sort-by {
      display: none;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }

    hr {
      margin-top: 0 !important;
      border: 1px solid #000000;
    }
    .badge {
      border: 1px solid #ebebeb !important;
      border-radius: 20px;
      padding: 6px 9px;
      color: #1d262e;
      background: #efefef;
      // font-size: ;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 320px;

      @media (min-width: 768px) {
        max-width: inherit;
      }
    }

    .filter-selection {
      padding-bottom: $spacing-2x;
      .filter-name {
        border: 1px solid #000000;
        padding: 0 $spacing-1x;
        font-size: $spacing-2x;
      }
      .filter-content {
        font-size: small;
        height: 200px;
        color: #9f9f9f;
        padding: $spacing-1x;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }

    .search-content-container {
      // .content-modal {
      //     @media (min-width: 768px) {
      //       width: 50%;
      //   }
      // }
      .pagination-container {
        .filter {
          display: inline-block;
          @media (min-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}

.isActive{
  color: red

}


.option-data{
    cursor :pointer;
}

.stickbutton{
  position: relative;
}

.stickbuttoncss{
  position: absolute;
  top:1px;
  right: 10px;
  padding: 2px;
}
.hoveronenquiry{
   cursor: pointer;
   color:#1476ba;

}
.hoveronenquiry:hover{
  text-decoration: underline;

}

.responsive{

  @media (min-width: 750px) {
    width: 600px;
    height:auto;
    max-height: 400px;
  }
  @media (max-width: 749px) {

    height:auto;
    max-height: 300px;
  }
}

.byline{
  @media (max-width: 749px) {
    font-size: 10px;
  }
  @media (min-width: 750px) {
    font-size: 15px;
  }
}
.newspostlogo{

  @media (max-width: 749px) {
    width: 45px;
  }
  @media (min-width: 750px) {
    font-size: 15px;
  }
}

.filter-block{
  @media (max-width: 768px) {
    display: none;
  }
}

.popfilhead{
  font-family: Work Sans;
font-size: 18px !important;
font-weight: 700 !important;
line-height: 32px !important;
letter-spacing: 1px !important;
text-align: left !important;
}
.searchiconcls{
  background-color: #f15d22;
  padding: 3px 6px 5px 8px;
  color:white;
  @media (max-width:286px){

    padding: 0px 2px 2px 4px;
  }
}

@media (max-width:575px) {
  .mobileContentTypes {
    display: none !important;
  }
  .sgtinput{
    text-align: center !important;
  }
}
@media (min-width:575px) {
  .searchiconcls {
    display: none !important;
  }
}

.sgtinput{
  @media (max-width :768px) {
    padding-bottom: 15px !important;
    padding-left: 15px;

  }
}

.textcenterclass{
  @media (max-width:576px){
    text-align: center;

  }


}
.fontcsss{
  font-weight: 600 !important;
}

.fontsizeHtag{
    font-weight: 400 !important;
}
