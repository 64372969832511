// @import "../../../index.scss";
@import "../../../assets/global/variables";

.advanced-search-container {
  background: #efefef;
  padding: $spacing-5x 0 !important;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

.headingadv{
  font-weight: 300;
  padding-top: 5px;
  text-align: left;
  font-size: 1.8rem;

}
h6{
  font-family:"Work Sans" ;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 3px;
}
  .dropdown-content {
    //   left: -172px;
    //   top: 10px;
    z-index: 1;
  }

  .select-button {
    border: 1px solid #1d262e;
    background: transparent;
    font-family: "Work Sans";
    font-style: normal;
    justify-content: left;
    font-weight: 500;
    color: #1d262e;

    .down-icon {
      justify-content: right;
    }
  }

  .search-container {
    padding: $spacing-1x;

    .search-input {
      background: transparent;
      border: none;
      border-bottom: 1px solid #9f9f9f;
      outline: none;
      color: #9f9f9f;
    }

    .search-icon {
      position: absolute;
      //   top: -1px;
      margin-top: $spacing-1x;
      left: 1px;
      color: #9f9f9f;
    }
  .search-icon-adsearch{
    position: absolute !important;
    //   top: -1px;
    margin-top: $spacing-1x;
    left: 1px;
    color: #9f9f9f;
  }
  .search-icon-advsearch{
    position: absolute !important;
    //   top: -1px;
    margin-top: $spacing-1x;
    left: 1px;
    color: #9f9f9f;
  }


    .cross-icon {
      position: absolute;
      color: #737171;
      z-index: 99 !important;
      right: 8px;
      top: 43px;
      cursor: pointer;
      opacity: 0;
    }
    .cross-icons {
      position: absolute;
      color: #737171;
    z-index: 99 !important;
    right: 8px;
    top: 43px;
    cursor: pointer;

    }
    .cross-iconsadv{
      position: absolute;
      color: #737171;
    z-index: 0 !important;
    right: 8px;
    top: 43px;
    cursor: pointer;

    }
  }
}

.popular-search-container {
  background: #ffffff;
  padding: $spacing-5x 0 !important;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

  .badge {
    border: 1px solid #ebebeb !important;
    border-radius: 20px;
    padding: 6px 9px;
    color: #1d262e;
    background: #efefef;
    font-size: large;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 320px;

    @media (min-width: 768px) {
      max-width: inherit;
    }
  }
}

.cross-iconss {
  position: absolute;
  color: #737171;
  z-index: 99 !important ;
  right: 8px;
  top: 9px;
  cursor: pointer;

}
.cross-iconad{
  position: absolute;
  color: #737171;
  z-index: 0 !important ;
  right: 8px;
  top: 9px;
  cursor: pointer;
}

.magic{
  .hidebutton{
    @media (max-width: 768px) {
      display: none !important;
    }
  }
  .hidebuttonsec{
    @media (min-width: 767px) {
      display: none !important;
    }
  }
}

.badgecss{
  .badge{
    font-size: small !important;
  }
}

@media (max-width: 414px) {

  .headingadv{
    font-size: 1.8rem;
    padding-left: 0px !important;
  }
}

.paddropsm{
  padding-left: 0px !important;
}
.tranparentscreen2{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  display: inline;
}

.fontcss{
  font-weight: 600 !important;
}

@media(max-width:282px){
  .btnpopup{
    font-size: 11px;
    ;
  }
}
