.CustomModal {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  box-sizing: border-box;
  transition: all 0.3s ease;
  height: auto;
  width: 40%;
  // height: 75%;

  // @media (min-width: 415px) {
  //       // top: 30% !important;
  //       width: 80% !important;
  // }
  // @media (max-width: 768px) {
  //   // top: 30% !important;
  //   width: 80% !important;
  // }

  .close-icon-container {
    // padding: 12px 16px;
    padding: 0px 16px;
    // height: 30px;
    .close-icon {
      z-index: 1;
      color: white;
      height: 24px;
      cursor: pointer;
    }

    .icon-img {
      float: right;
      width: 20px;
      margin-top: 5px;
      @media(max-width:565px){
        width: 12px !important;
      }
    }
  }

  .modal-content {
    padding: 16px;
  }
}


::-webkit-scrollbar
{
  width: 5px;  /* for vertical scrollbars */
  height: 5px; /* for horizontal scrollbars */
  border-radius: 20px;
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}


.forlogoutpopuponly{

    @media (max-width: 550px) {
      // top: 30% !important;
      width: 90% !important;
}

}
