.fromMargin {
    margin-left: 7px !important;
}

.paddingLeft {
    padding-left: 0px !important;
}

.card {
    // background-color: #EFEFEF;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.top10 {
    margin-top: 10px;
}
.top15{
    margin-top: 15px;

}
.top5 {
    margin-top: 5px !important;
}

.err {
    color: red;
}

.suc {
    color: green;
}

.disabledTextBox {
    background-color: gainsboro;
}

.padding10 {
    padding: 10px !important;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-top-15 {
    margin: 15px 0px;
}

.createAgency {
    color: #0000EE;
    cursor: pointer;
}

// .CustomModal{
//     width: 40% !important;
// }

.btn {
    width: 20%;
}

.table-header {
    background-color: #efefef;
}


tr:hover {
    background-color: #efefef;
}

.underline {
    text-decoration: underline !important
}

.float-left {
    float: left;
}

.clear-float {
    clear: both;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa !important;
    margin: 0 0 10px;
    padding: 0;
    // display:revert;
    // white-space: normal;
}

.react-tabs__tab {
    // border-bottom: 1px solid #aaa !important;
    margin-top: 5px;

}

.periodErr {
     margin-left: 195px;;
}
.blockdiv{
    height: 1px;
    width: 1px;
}
@media (max-width: 394px) {
    .mtop-10 {
        margin-top: 10px !important;
    }
}
.margin-left-0{
    margin-left : 0px !important;
}
//   .react-tabs__tab--selected {
//     display: inline-block;
//     border-right: 3px solid #f15d22;
//     border-left: 3px solid #f15d22;
//     border-top: 3px solid #f15d22;
//     border-bottom: none;
//     // bottom: -1px;
//     position: relative;
//     list-style: none;
//     padding: 6px 12px;
//     cursor: pointer;
//     font-size: 17px;
//     font-weight: 500;
// }
