.table-container {
  // background: #ffffff;
  padding: 8px;

  .table {
    background: #ffffff;
    table-layout: auto;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    line-height: 26px;
    color: #000000;


    th {
      border-top: 0;
    }

    .table-header {
      border-bottom: 2px solid #000000;
      background-color: #efefef;
    }

    .table-header-chngbg {
      border-bottom: 2px solid #000000;
      background-color: white !important;

    }
  }

  .table1 {
    table-layout: auto;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    line-height: 26px;
    color: #000000;


    th {
      border-top: 0;
    }

    .table-header {
      border-bottom: 2px solid #000000;
      background-color: #efefef;
    }

    .table-header-chngbg {
      border-bottom: 2px solid #000000;
      background-color: white !important;

    }
  }

  .tablebg {
    background: #efefef;
  }

}

.decorline {
  text-decoration: underline;
  text-decoration-color: #242f39;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
}

.selected {
  background-color: #ced0d2 !important;
}

.actionLogo {
  height: 40px;
  width: 40px;
}

.actionLogo:hover {
  background-color: #efefef;
}

.actions {
  display: flex;
}

.tablenl {
  background-color: #efefef !important;
}

.table-body {
  background-color: white;
}

.wrapWord {
  word-break: break-word;
}

.whitespace {
  white-space: nowrap;
}
