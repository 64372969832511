$gb: #F2EEE8;
$wh: #FFFFFF;
$dark: #383A41;
$red: #EB6D6D;
$blue: #5E7FDC;

$break: 400px;

.erroricon {
	display: block;
	margin: auto;
	margin-top: 100px;
}

.face {
	width: 300px;
	height: 300px;
	border: 4px solid $dark;
	border-radius: 10px;
	background-color: $wh;
	margin: 0 auto;
	margin-top: 40px;

	@media screen and (max-width: $break) {
		margin-top: 40px;
		transform: scale(.8);
	}

	.band {
		width: 350px;
		height: 27px;
		border: 4px solid $dark;
		border-radius: 5px;
		margin-left: -25px;
		margin-top: 50px;

		.red {
			height: calc(100% / 3);
			width: 100%;
			background-color: $red;
		}

		.white {
			height: calc(100% / 3);
			width: 100%;
			background-color: $wh;
		}

		.blue {
			height: calc(100% / 3);
			width: 100%;
			background-color: $blue;
		}

		&:before {
			content: "";
			display: inline-block;
			height: 27px;
			width: 30px;
			background-color: rgba($wh, 0.3);
			position: absolute;
			z-index: 999;
		}

		// &:after {
		// 	content: "";
		// 	display: inline-block;
		// 	height: 27px;
		// 	width: 30px;
		// 	background-color: rgba($dark,0.3);
		// 	position: absolute;
		// 	z-index: 999;
		// 	right: 0;
		// 	margin-top: -27px;
		// }
	}

	.eyes {
		width: 128px;
		margin: 0 auto;
		margin-top: 40px;

		&:before {
			content: "";
			display: inline-block;
			width: 30px;
			height: 15px;
			border: 7px solid $dark;
			margin-right: 20px;
			border-top-left-radius: 22px;
			border-top-right-radius: 22px;
			border-bottom: 0;
		}

		&:after {
			content: "";
			display: inline-block;
			width: 30px;
			height: 15px;
			border: 7px solid $dark;
			margin-left: 20px;
			border-top-left-radius: 22px;
			border-top-right-radius: 22px;
			border-bottom: 0;
		}
	}

	.dimples {
		width: 180px;
		margin: 0 auto;
		margin-top: 15px;

		&:before {
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			margin-right: 80px;
			border-radius: 50%;
			background-color: rgba($red, 0.4);
		}

		&:after {
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			margin-left: 80px;
			border-radius: 50%;
			background-color: rgba($red, 0.4);
		}
	}

	.mouth {
		width: 40px;
		height: 5px;
		border-radius: 5px;
		background-color: $dark;
		margin: 0 auto;
		margin-top: 25px;
	}
}

h1.errorpage {
	font-weight: 800;
	color: $dark;
	text-align: center;
	font-size: 2.5em;
	padding-top: 20px;

	@media screen and (max-width: $break) {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 2em;
	}
}

.btn-error {
	color: white;
	width: 300px;
	margin: 0 auto;
	text-align: center;
	font-size: 1em;
	cursor: pointer;
	margin-top: 40px;
	margin-bottom: 50px;
	transition: all .2s linear;
	font-family: "Barlow", sans-serif;
	opacity: 1;
	padding: 20px;
	font-weight: 600;
	letter-spacing: 0;
	border-radius: 40px !important;
	border: none;

	@media screen and (max-width: $break) {
		margin: 0 auto;
		margin-top: 60px;
		margin-bottom: 50px;
		width: 200px;
	}

	&:hover {
		transition: all .2s linear;
	}
}

@media(max-width:500px) {
	.breakWord {
		overflow-wrap: anywhere;
	}
}
