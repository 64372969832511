.users-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  padding: 1rem;

  .heading {
    font-weight: 300;
  }

  .users-table {
    padding-top: 2rem;
    .table-header {
      background: #EFEFEF;
    }
  }
}
.action-container{
  margin-bottom: 10px;
}
