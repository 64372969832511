* {
  margin: 0;
  padding: 0;
}

.heading {
  font-weight: 300;
  padding-top: 5px;
}

.nl_magazine_search {
  margin-top: -14px;
  margin-left: 99px;
}

.nl_magazine_search1 {
  margin-left: 113px;
}

.nl_sphm {
  margin-left: 2px !important;
  margin-right: 10px !important;
}

.nl_status {
  margin-top: 15px;
  margin-left: 15px;
}

@media(min-width:768px) {
  .mt-20 {
    margin-top: 20px;
  }
}

.font-1 {
  font-size: 0.9em;
}

.nl_tel {
  width: 60% !important;
}

label {
  font-weight: 500;
  white-space: normal;
}

.card {
  // background-color: #EFEFEF;
  border-radius: 5px;
  // margin: 2px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.disabled {
  background-color: #ececeb;
}

.activeSubscription {
  white-space: nowrap;
}

@media (max-width: 414px) {
  .button-res {
    flex: auto;
    display: grid;
  }

  .mt-10 {
    margin-top: 5px !important;
  }

  .heading {
    font-size: 1.8rem;
  }
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  // width: 100%;
  // height: 100%;
  z-index: 9999;
}

.requiredStar {
  color: red;
}

@media (max-width: 537px) {
  .button-res {
    flex: auto;
    display: grid;
  }
}

@media(max-width:280px) {
  .create-modal {
    width: 85% !important;
    top: 51% !important;
    font-size: 10px;
  }
}

@media (max-width: 540px) {
  .create-modal {
    width: 85% !important;
    top: 51% !important;
  }

  .cheque-mt {
    margin-top: 20px;
  }

  .heading-bold {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .margintop {
    margin-top: 20px;
  }
  .nl_magazine_search {
   font-size: 12px;
  }
  // .lable_font{
  //   font-size: 13px;
  // }

}

@media (min-width: 541px) and (max-width: 767px) {
  .create-modal {
    width: 85% !important;
    top: 45% !important;
  }
}

@media (min-width: 768px)and (max-width:912px) {
  .create-modal {
    width: 50% !important;
    top: 40% !important;
  }

}
