.users-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  padding: 1rem;

  .heading {
    font-weight: 300;
  }

  .users-table {
    padding-top: 2rem;

    .table-header {
      background: #EFEFEF;
    }
  }
}

.decorline {
  text-decoration: underline;
  text-decoration-color: #242f39;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  color: #007bff;

}

.pendingSub {
  color: #000080;
  // font-size: 20px;
  font-weight: 600;
}

.inactiveSub {
  color: #B8860B;
  // width:20px;
  // font-size: 20px;
  font-weight: 600;
}

.activeSub {
  color: #2E8B57;
  // font-size: 20px;
  font-weight: 600;
}

.closeSub {
  color: red;
  // font-size: 20px;
  font-weight: 600;

}

.suspended {
  color: #d336a4;
  // font-size: 20px;
  font-weight: 600;
}

.upgraded {
  color: #32CD32;
  // font-size: 20px;
  font-weight: 600;
}

.Deleted {
  color: red;
  // font-size: 20px;
  font-weight: 600;
}

//  .login-modal{
//    width:100% !important;
//  }

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  // width: 100%;
  // height: 100%;
  z-index: 9999;
}
