
.checkboxlabel{
    white-space: nowrap;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    // margin-bottom: 12px;
    margin-bottom: 0px !important;
}
.flexcheckbox{
    flex-basis: 190px;
}

.right{
    float: right;
}

.selectlist-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .selectlist-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }
  .padding5px{
      padding-right: 5px;
  }
  .paddingBottom2px {
    padding-bottom: 2px !important;
  }
  .capitalize{
    text-transform: capitalize;
  }
