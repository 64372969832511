.article-container, .online-container {
  height: auto;

  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    /* or 133% */

    letter-spacing: 0.0583333px;
    text-decoration-line: underline;
  }

  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: small;
  }

  .img-block {
    // display: none;

    @media (min-width: 768px) {
      // display: inline-block;
    }
    @media (max-width: 768px) {
      // display: inline-block;
      height: 300px;
      width: 80%;

    }
    height: 200px;

    border: 1px solid;

  }


  .by {
    color: #9f9f9f;
    line-height: 19px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-transform: uppercase;
  }
  .pageName {
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration-line: underline;
  }
  .pageName2 {
    font-weight: 500;
    letter-spacing: 1px;

  }

}

.image-container {

  .image {
    height: 200px;
    img {
      object-fit: contain;
    }
  }

  h6 {
    font-size: medium;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    /* or 125% */

    letter-spacing: 0.0583333px;
    text-decoration-line: underline;
  }

  p,
  .pageName {
    font-size: small;
  }

  .pageName {
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration-line: underline;
  }

  .by {
    color: #9f9f9f;
    line-height: 19px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
}

@media (min-width :768px){
  .ellipses{
      text-overflow: ellipsis;
      overflow: hidden;
      width: auto;
      white-space: nowrap;
  }

}
.ellipsesforall{
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;

}

.enquiry{
 cursor: pointer;
}
.reshape{
  font-family: Lato;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: small;
}


.headline_en{

  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  font-size: 14px;

}
.topicon{
position: fixed !important;
top: 0px !important;
}



.imagess{
  width: 100%;
  height: 200px;
  margin: auto;
  display: block;
  border: 1px solid black;


img{
  object-fit: contain;
}
@media (max-width: 768px) {
  // display: inline-block;
 width: 70% !important;

}

}

.hideimg{
  @media (max-width: 768px) {
    display: none


  }
}

.center{
  margin-left: auto;
  margin-right: auto;
}

.hidethebr{
  @media (max-width: 767px) {
    display: none
  }
}
.fontlikeh6{
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  // line-height: 24px;
  font-size: 16px;

}
