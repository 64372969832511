.custom_pagination {
  transition: 400ms ease;
  color: #595959;
  font-size: 1rem;
  padding-top: 0.5rem;

  &__pgno {
    // color: #0a9fc2 !important;
    font-size: larger;
    background: #fff;
    border: none;

    &.active {
      // color: white !important;
      font-weight: 500;
      // background-color: #0a9fc2;
    }

    &:hover {
      background-color: #e1dedf;
      color: white;
    }

    &.current {
      // background-color: #0a9fc2;
      color: #fff;
    }
  }

  &__left {
    color: #242f39;
    padding: 0 15px;
    border: none;
    background-color: transparent;
    font-size: xx-large;

    &.font-large {
      font-size: large;
      padding: 0 10px;
      border: 1px solid grey;
    }

    &:active {
      border: 1px solid #0a9fc2;
      background-color: #0a9fc2;
      color: white;
    }

    &:hover {
      // background-color: #f15d22;
      // color: white;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  select {
    font-size: 16px;
    border-radius: 0;
    border: 1px solid #e1dedf;
    height: 33px;
    width: 50px;
    padding: 0.3rem;
  }

  .page-number {
    padding: 0 10px;
  }
}

.isactive {
  color: red;

}

.page-link {
  color: #1d262e !important;
  @media (min-width:526px) and (max-width:821px) {
  padding: 0.5rem 0.6rem !important;
//
  }
}

.cursor {
  cursor: pointer;
}

.contentadd:before {
  @media (min-width:821px) {
    content: "Previous";

  }

  @media (max-width:821px) {
    content: "<";
  }
}

.contentnext:before {
  @media (min-width:821px) {
    content: "Next";
  }

  @media (max-width:821px) {
    content: ">";
  }

}

@media(max-width:448px) {
  .pagination {
    // font-size: 14px;
    // max-width: 300px;
  }

  // .page-item{
  //   font-size: 8px;
  // }
}

@media (min-width:449px) and (max-width:821px) {

  // .pagination {
  //   font-size: 10px;
  // }
  // .page-item {
  //   font-size: 6px;
  // }
}


.MuiPaginationItem-root.Mui-selected {
  color: #000 !important;
  border: 1px solid rgba(25, 118, 210, 0.75) !important;
  background-color: #007bff !important;
}

.MuiButtonBase-root {
  margin: 0px !important ;
  border: 1px solid rgba(1, 15, 29, 0.75) !important;
  border-radius: 0 !important;
  font-size: 18px !important;
}