// @import "../../../index.scss";

.forgot-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

  .form-control {
    padding-left: 0;
  }

  .reset-password {
    border-radius: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 2px;
  }
}
.login-heading {
      word-wrap: break-word;
    }
