// @import "../../../index.scss";
.login-container {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    border: 1px solid #efefef;
    background: white;



      text-align: center;
      @media (max-width: 767px) {
        width: 100%;
      }


      @media (min-width: 768px) {
        width: 40%;
        box-shadow: 3px 5px 4px rgba(0 ,0, 0, 0.25);
      }


    .login-heading {
      font-weight: 500;
      color: #1d262e;
      float: left;
    }

    .form-control {
      padding-left: 0;
    }

    .forgot-password {
      color: #f15d22;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .login-button {
      border-radius: 0;
      padding: 6px 24px;
    }
  }

  .viewheight{
    // height: 100vh;
    padding-top: 5%;
    padding-bottom: 2%;
  }
  .remindclass{
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #1d262e;
      float: left;
  }
