.user-preference-container {
    background: #efefef;
    padding: 1.5rem;

    .heading {
        font-weight: bold !important;
    }

    .container {
        // background: white;
        padding: 1rem 1.5rem;
    }
}

@media(max-width:280px) {
    .forgot-password-modal {
        width: 85% !important;
        top: 50% !important;
        font-size: 10px;
    }
}

@media (max-width: 677px) {
    .forgot-password-modal {
        width: 85% !important;
        top: 50% !important;
    }
}

@media (min-width: 768px)and (max-width:912px) {
    .forgot-password-modal {
        width: 50% !important;
        top: 50% !important;
    }
}

.bg-white {
    background: white;

}

// @media (min-width: 820px) {
//     .forgot-password-modal {
//         width: 50% !important;
//         top: 20% !important;
//     }
// }
