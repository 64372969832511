.lab {
  // white-space: nowrap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
  background-color: white;

}
.marginBottom{
  margin-bottom : 0px !important;
}
.heading {
  font-weight: 300;
  padding-top: 5px;
  font-weight: 500;
}

.padline {
  border-top: none !important;
  border-right: none;
  border-left: none;
  padding: 0;
}

.card {
  // background-color: #EFEFEF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  // background-color: #efefef !important;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  // width: 100%;
  // height: 100%;
  z-index: 9999;
}

.disabled {
  background-color: #ececeb;
}

@media (max-width: 414px) {
  .button-res {
    flex: auto;
    display: grid;
  }
}
