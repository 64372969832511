.footer {
  // position: fixed;
  // bottom: 0;
  font-family: 'Work Sans' !important;
  width: 100%;

  img.logos {
    // width: 50%;

    // @media (min-width: 768px) {
    //   width: 10%;
    // }
    // width: 45% !important;
    width: 160px !important;
    // padding-bottom: 0px !important;
    @media (min-width: 769px) and (max-width : 850px) {
      width: 160px !important
    }
    @media (min-width: 468px) and (max-width: 768px) {
      // width: 75% !important;
      width: 160px !important;
    }
    @media (max-width: 468px) {
      width: 160px !important;
    }
    @media (max-width: 420px) {
      // width: 150% !important;
      width: 150px !important;
    }
    @media (max-width: 323px) {
      // width: 150% !important;
      width: 140px !important;
    }
    // @media (max-width: 278px) {
    //   width: 200% !important;
    // }
  }

  .footer-black {
    background: #242f39;
    color: #ffffff;

    .footer-block {
      @media (max-width: 767px) {
        border-bottom: 1px solid #ffffff;
      }

      @media (min-width: 768px) {
        border-right: 1px solid #ffffff;
      }
    }

    .footer-links {
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #ffffff;

      @media(min-width:768px) and (max-width:820px) {
        font-size: 6px;
      }
    }


    .helpdesk-section {
      color: #efefef;

      .footer-links {
        color: #efefef;
      }
    }
  }

  .cursor {
    cursor: pointer;
  }

  .content {
    color: lightgrey !important;
  }
}

@media (min-width: 768px) {
  .address {
    margin-top: -21px;
  }


}

.emailfooter {
  word-wrap: break-word;
}

.copyright {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Work Sans';
  color: black;

}

.data-pro {
  white-space: nowrap;
  font-size: 9px;
}

@media (min-width:481px) {
  .up-down-arrow {
    display: none;
  }
}


@media (max-width:480px) {
  .up-down-arrow {
    padding-right: 20px;
  }
}
