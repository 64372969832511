// @import "../../../index.scss";

.forgot-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

  .form-control {
    padding-left: 0;
  }

  .reset-password {
    border-radius: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.err {
  color: red;
  font-weight: 700;
}

.suc {
  color: green;
  font-weight: 700;
}

.listStyle {
  list-style-type: none;
}

.btn-right {
  float: right;
  // color: green;
}
.btn-center{
  // align-items: center;
  text-align: center;
}
.icon-imgss {
  float: right;
  width: 40px;
  margin-top: 5px;
}
.tick{
  margin-left: 30%;
}

.error-popup-ul li{ 
  overflow-wrap: anywhere;
}
// .breakmsg {
//   word-wrap: break-word !important;
// }
