.MuiFormControl-root {
    width: 100%;
}

// *,
// *::before,
// *::after {
//     box-sizing: border-box;
// }

.form-control {
    border: none !important;
    // border-bottom: none;
    // border-radius: 0 !important;
    // color: #1d262e !important;
}

.badge {
    margin-bottom: 2px !important;
}

.group {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: stretch !important;
    width: 100% !important;
    overflow: auto !important;
    // border: 1px solid black;
    /* height: 12vh; */
    border: 1px solid black;
    height: 100px;
}

.inputGroup {
    border-bottom: none !important;
    width: 100px;
}

.bootstrap-tagsinput {
    width: 100%;
}

.label-info {
    background-color: #17a2b8;

}

.label {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out,
        border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.group>.flex-wrap>.my-auto {
    margin: 3px !important;
}

.mailInfo {
    color: red
}
