// @import "../../../index.scss";

.forgot-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;

  .form-control {
    padding-left: 0;
    border: none !important;
    border-bottom: 1px solid #1d262e !important;
    border-radius: 0 !important;
    color: #1d262e !important;
  }
  .form-control:focus {
    color: #1d262e !important;
    background-color: #fff !important;
    border-bottom: 1px solid #1d262e !important;
    border-color: #1d262e !important;
    outline: 0 !important;
    box-shadow: none !important;
  }

  .reset-password {
    border-radius: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .err {
    color : red;
  }
}
