// @import "../../../index.scss";
@import "../../../assets/global/variables";

.main-navbar-container {
  .navbar-container {
    padding-left: $spacing-1x;

    .logo {
      width: 45% !important;
      padding-bottom: 0px !important;
      @media (min-width: 769px) and (max-width : 850px) {
        width: 65% !important;
      }
      @media (min-width: 468px) and (max-width: 768px) {
        // width: 75% !important;
        width: 160px !important;
      }
      @media (max-width: 468px) {
        width: 160px !important;
      }
      @media (max-width: 420px) {
        // width: 150% !important;
        width: 150px !important;
      }
      @media (max-width: 323px) {
        // width: 150% !important;
        width: 140px !important;
      }
      @media (max-width: 278px) {
        width: 200% !important;
      }
    }

    .sign-in {
      min-width: 40px !important;
      padding: $spacing-1x  !important;
      @media (max-width: 300px) {
        padding:6px !important
      }
      @media (min-width: 768px) {
        min-width: 64px !important;
        padding: 5px 15px !important;
      }

      .button-label {
        @media (min-width: 768px) {
          display: inline !important;
        }
      }
    }

    .login-button {
      min-width: 40px !important;
      padding: $spacing-1x  !important;
      @media (max-width: 300px) {
        padding:6px !important
      }
      @media (min-width: 768px) {
        min-width: 64px !important;
        padding: 5px 15px !important;
      }

      .button-label {
        @media (min-width: 768px) {
          display: inline !important;
        }
      }
    }

    .userAction {
      float: right;
      display: none;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }

    .arrow-span {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }

    .userName {
      line-height: 2.2;
    }

    .arrow-dropdown-main-container {
      display: none;
      position: relative;
      z-index: 9999;
      top: 1rem;

      .arrow-dropdown-sub-container {
        background-color: #f8f8f8;
        position: absolute;
        min-width: 165px;
        top: 1rem;
        right: 0;

        .arrow-dropdown {
          padding: 10px;
          cursor: pointer;

          &:hover {
            background-color: #e9e9e9;
          }
        }
      }
    }
  }

  .navlinks-container {
    background: #242f39;
    color: wheat;

    .navbar-nav {
      @media (min-width: 320px) and (max-width: 568px) {
        padding-left: 15px;
      }

      .active {
        @media (min-width: 575px) {
          border-bottom: 4px solid #dc1f23;
        }
      }
    }

    .navbar-text-heading {
      text-transform: uppercase;
      // vertical-align: bottom !important;
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: small;
      line-height: 21px;
      letter-spacing: 1px;
      // color: black;

      // @media (min-width: 768px) {
      color: #ffffff;
      // }

      @media (max-width: 921px) {
        font-size: x-small;
      }
    }

    .link-text {
      background: #dc1f23 !important;
      color: white;
    }
  }

  .navlinks-container-admin {
    background: #242f39;
    color: wheat;
    height: 5px;

    z-index: -1 !important;

    .navbar-nav {
      @media (min-width: 320px) and (max-width: 568px) {
        padding-left: 15px;
        z-index: -1 !important;


      }

      .active {
        @media (min-width: 575px) {
          border-bottom: 4px solid #dc1f23;
        }
      }
    }

    .navbar-text-heading {
      text-transform: uppercase;
      // vertical-align: bottom !important;
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: small;
      line-height: 21px;
      letter-spacing: 1px;
      // color: black;

      // @media (min-width: 768px) {
      color: #ffffff;
      // }

      @media (max-width: 921px) {
        font-size: x-small;
      }
    }

    .link-text {
      background: #dc1f23 !important;
      color: white;
    }
  }

  @media (max-width: 768px) {
    .navlinks-container-admin {
      display: none;
    }
  }


  .navbar-dark {

    // .bg-dark
    .navbar-toggler {
      border: none;
    }

    .navbar-toggler-icon {
      background-image: url("../../../assets/images/icon\ hamburger.svg");
    }
  }

  .login-modal {
    @media (max-width: 912px) {
      // width: 50%;
      padding-top: 50px;
    }
    // @media (max-width: 768px) {
    //   width: 50%;
    // }
  }
  .forgot-password-modal {
    @media (min-width: 768px) {
      width: 45%;
    }
  }
}

.token-expire-modal {
  @media (min-width: 768px) {
    width: 50%;
  }

  z-index: 99999 !important;
}


// @media (min-width: 768px) {
// .navbar-expand-sm .navbar-toggler {
//     display: block !important;
// }
// }

.floatleft {
  float: left;
}

.timout-window {
  z-index: 99999 !important;
}



.brss {
  overflow-wrap: break-word;
}

.horixontalLine {
  color: black !important;
}

.lass{
  @media (max-width: 768px) {
    height: 2px;
    background-color: #242f39;
  }
}
.lasses{
  display: none;
}


.userActionforhi {
  float: right;
  // display: none;
  @media (min-width:768px) and (max-width:842px){
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 290px;
    overflow: hidden;
    }
  @media (max-width:638px){
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    }
    @media (max-width:555px){
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      }
  @media (max-width:420px){
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    font-size: 10px;
    padding-top: 2px;
    }
  @media (max-width:220px){
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  }
    // display: inline-block;

}
.fontforadwelcm{
  font-family: Work Sans;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 1px;
text-align: right;
}

.icon-imgsss {

  width: 20px;
  // margin-top: 5px;
}
@media (min-width :401px){
  .gf{
   display: none;
  }
}

@media (max-width :400.9px){
  .fg{
   display: none;
  }
}

@media (min-width :577px){
  .gfmen{
   display: none;
  }
}

@media (max-width :576px){
  .fgmen{
   display: none;
  }
  .navbrinline{
    display: inline !important;
  }
  .navbar{
    padding: 0px !important;
  }
}

.adcss{
  font-family: Work Sans;
font-size: 18px;
font-weight: 600;
line-height: 37px;
letter-spacing: 0px;
text-align: center;
color: #ffffff;
padding: 10px;
}

.usericon{
  @media (min-width: 768px){
    display:none
  }
}
@media (max-width: 425px){
 .userActionforhi{
  display: none !important;
 }
}
@media (min-width :425px){
  .toshownameuser{
    display: none;
  }
}

.hiinpopup{
  width: 100%;
  word-wrap: normal;
  word-break: break-all;
}

.tranparentscreen{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: inline;
}
.downloadbarmod{
  position: absolute;
  top:60px;
  z-index: 999999 !important;
}
.downloadbarmodhide{
  display: none;
}
