// @import "../../../index.scss";
@import "../../../assets/global/variables";
.user-account-container {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  .heading {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
  }
  .tablist {
    margin: 0;
    display:inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    padding-top: $spacing-2x !important;

    @media (min-width: 768px) {
      display: inline-block;
      padding-top: $spacing-8x !important;
    }
  }
  .react-tabs__tab {
    display: block;
    color: #9f9f9f;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    border-radius: 0;
    bottom: 0;
    font-size: 18px;
    margin: 1px;
    background: #efefef;

    @media (min-width: 768px) {
      background: #fff;
      margin: 0;
    }
  }
  // .react-tabs__tab--selected {
  //   font-family: Work Sans;
  //   font-style: normal;
  //   color: #1d262e;
  //   border: none;
  //   border-radius: 0;
  //   background: #efefef;
  //   font-weight: 500;
  //   border-bottom: 5px solid #242f39;
  //   box-shadow: none;

  //   @media (min-width: 768px) {
  //     border-bottom: none;
  //   }
  // }
}
