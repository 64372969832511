//font-variables

$font-family: 'Barlow', sans-serif;

$spacing-1x: 8px;
$spacing-2x: 8px * 2;
$spacing-3x: 8px * 3;
$spacing-4x: 8px * 4;
$spacing-5x: 8px * 5;
$spacing-6x: 8px * 6;
$spacing-7x: 8px*7;
$spacing-8x: 8px * 8;
