.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-top-15 {
    margin: 15px 0px;
}

.paddingLeft {
    padding-left: 0px !important;
}

.padding10 {
    padding: 10px !important;
}

.top10 {
    margin-top: 10px;
}

.createAgency {
    color: #0000EE;
    cursor: pointer;
}

// .CustomModal{
//     width: 40% !important;
// }

.btn {
    width: 20%;
}

.table-header {
    background-color: #efefef;
}

.card {
    background-color: #EFEFEF;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

tr:hover {
    background-color: #efefef;
}

.underline {
    text-decoration: underline !important
}

.disabledTextBox {
    background-color: gainsboro;
}

.err {
    color: red;
}

.suc {
    color: green;
}

.spinner {
    position: fixed;
    left: 0px;
    top: 25%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.decorline {
    text-decoration: underline;
    text-decoration-color: #242f39;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
}

.active {
    color: #2E8B57;
    // font-size: 20px;
    font-weight: 600;
}

.closeStatus {
    color: red;
    font-weight: 600;
}

@media only screen and (min-width : 400px) {
    // .CustomModal{
    //     width: 85% !important;
    // }
}

.agency>.table-container>.table {
    background-color: white !important;
}

@media (max-width: 537px) {
    .button-res {
        flex: auto;
        display: grid;
    }
}

.margin-top10 {
    margin-top: 10px !important;
}
@media (max-width: 394px) {
    .mt-10 {
        margin-top: 10px !important;
    }

}
