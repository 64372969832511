.subfeees{
    @media (max-width:456px){
        font-size: 18px !important;
    }
}
.dollorcard {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 49px;
    @media (max-width:456px){
        font-size: 18px !important;
    }
  }
