.CustomModals {
    position: fixed;
    z-index: 100;

    transform: translate(-50%, -50%);
    margin: 0 auto;
    box-sizing: border-box;
    transition: all 0.3s ease;


    // height: 75%;

    // @media (max-width: 376px) {
    //       // top: 30% !important;
    //       width: 80% !important;
    // }
    @media (min-width: 750px) {
        top: 45%;
        left: 50%;
        height: auto;
        width: auto;
        max-height: 80% !important;
        max-width: 100% !important;
        margin-right: -50%;
    }
    @media (max-width: 749px) {
        top: 25%;
        left: 50%;
        width: auto;
        height: auto;
        max-height: 40% !important;
        max-width: 100% !important;
        margin-right: -50%;
    }

    .close-icon-container {
      // padding: 12px 16px;
      padding: 0px 16px;
      // height: 30px;
      .close-icon {
        z-index: 1;
        color: white;
        height: 24px;
        cursor: pointer;
      }

      .icon-img {
        float: right;
        width: 20px;
        margin-top: 5px;
      }
    }

    .modal-content {
      padding: 16px;
    }
  }


  ::-webkit-scrollbar
  {
    width: 5px;  /* for vertical scrollbars */
    height: 5px; /* for horizontal scrollbars */
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track
  {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb
  {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
