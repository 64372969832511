

  /* Popover styling */

  a {
    text-decoration: none;
  }

  .popover__title {
    font-size: 15px;
    line-height: 36px;
    text-decoration: none;
    /* color: rgb(228, 68, 68); */
    text-align: center;
    padding: 15px 0;
  }

  .popover__wrappersc {
    position: relative;
    /* margin-top: 0.5rem; */
    display: inline-block;
  }
  .popover__contentnew {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 64px;
    /* transform: translate(0, 10px); */
    background-color: #ffffff;
    padding: 1.5rem;
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); */
    width: 100%;
    height :350px;
    overflow: auto;
    border:2px solid #737679;
    display: inline-block;
  }
  .popover__contentnew:before {
    position: absolute;
    /* z-index: 5; */
    content: "";
    /* right: calc(50% - 10px); */

    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .popover__wrappersc  {

    visibility: visible;
    transform: translate(0, -20px);
    /* transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); */
  }
  .popover__contentnew {

    visibility: visible;
    transform: translate(0, -20px);

    /* transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97); */
  }
  .popover__message {
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .openpopnew{

    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);

  }
  .nonopenpopnew{
      display: none;
  }
  .cons {
    /* ... */
    display: flex;
    justify-content:space-between;
    align-items:flex-start;
  }
 /* .align{
     float: left;
     padding: 2px;
 } */




 .form-fieldnew{

  height: auto;
  min-height: 34px;
  border: 2px solid #737679;
  padding: 5px;
  /* margin: 8px; */
  cursor: pointer;
  border-radius: 0px;

  /* box-shadow: 0 2px 6px rgba(25,25,25,0.2); */
}

.form-fieldnew .chips .chip {
  display: inline-block;
  width: auto;

  background-color: #bfbfbf;
  color: #bfbfbf;
  border-radius: 3px;
  margin: 2px;
  overflow: hidden;
}
.form-fieldnew .chips .chip{
  float: left;
}

.form-fieldnew .chips .chip .chip--button {
  padding: 8px;
  cursor: pointer;
  background-color: #bfbfbf;
  display: inline-block;
}
.form-fieldnew .chips .chip .chip--text {
  padding: 8px;
  cursor: no;
  display: inline-block;
  pointer-events: none
}

/* .form-fieldnew > input{

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  border: none;
  margin: 5px 0 0;
  display: inline-block;
  background-color: transparent;
} */

.close{
  font-size: 20px;
  margin: 2px;
  padding-left: 15px;
}


.badge-light {
  background-color: #bfbfbf;
}


.iconcss{
   position: relative;

}

@media (min-width:768px){
    .popover__contentnew{
      z-index: 1;
    }
    /* .form-fieldnew{
      z-index: 12 !important;
    } */
    .formfieldbrdr{
      height: 42px;
    }
}


@media (max-width:768px){
  .popover__contentnew{
    top:60px !important;
    z-index: 1;
  }

  .popupback{
      z-index: 1 ;
  }

.popover__wrappersc{
  transform: translate(0, -18px);
}
}
.fieldfront{
  z-index: 12 ;
}


.formfieldbrdr{
  border-bottom: 0px solid #ffffff;
  background-color: #ffffff ;
}


.hight{
  height: 8px !important;
}
.icontoright{
   float: right !important;
}
